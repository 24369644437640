.prepared-shipment-pane {
  margin: 0.2vw;
  width: 100%;
}

.prepared-shipment-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  width: 100%;
  font-size: 1.3vw;
  border: 0.1vw solid cornflowerblue;
}

@media (hover: hover) {
  .prepared-shipment-container:hover {
    cursor: pointer;
    border: 0.1vw solid orange;
    border-radius: 0.5vw;
  }
}

.prepared-shipment-container-selected {
  display: flex;
  flex-direction: column;
  box-shadow: 0.1vw 0.1vw 1vw darkorange;
  border-radius: 0.5vw;
  width: 100%;
  font-size: 1.3vw;
  border: 0.1vw solid darkorange;
  color: rgb(0, 51, 255);
}

@media (hover: hover) {
  .prepared-shipment-container-selected:hover {
    cursor: pointer;
    border: 0.1vw solid orange;
    border-radius: 0.5vw;
  }
}

.prepared-shipment-vertical-segment {
  display: flex;
  flex-direction: column;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  /* padding-top: 0.2vw; */
}

.prepared-shipment-horizontal-segment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  white-space: nowrap;
}

.prepared-shipment-horizontal-delimiter {
  border-top: 0.1vw solid cornflowerblue;
  /* margin-top: 0.2vw; */
}

.prepared-shipment-vertical-delimiter {
  border-right: 0.1vw solid cornflowerblue;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
}

.prepared-shipment-attribute-label {
  font-weight: 700;
  font-size: 1.4vw;
}

.prepared-shipment-attribute-value {
  padding-left: 0.2vw;
}

.prepared-shipment-id-label {
  font-size: 1.4vw;
}

.prepared-shipment-date-label {
  font-size: 1.2vw;
}

.prepared-shipment-padding-bottom {
  padding-bottom: 0.2vw;
}

.product-packing-pane {
  display: flex;
  position: relative;
  width: 100%;
}

.swal2-container.swal2-backdrop-show {
  background: rgba(0, 0, 0, 0.1);
}

.swal2-container {
  transition: 0.1s filter;
  backdrop-filter: blur(5px);
}

.products-packing-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
  width: 100%;
}

.product-packing-notification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5vw;
  min-height: 80vh;
  margin: 0.5vw;
  width: 100%;
}

.product-packing-notification-container-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5vw;
  width: 100%;
  min-height: 80vh;
  margin: 0.5vw;
}

.product-packing-notification-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
}

.product-packing-notification-message-container-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
}

.product-packing-notification-label {
  font-weight: 400;
  font-size: 2.5vw;
}

.product-packing-button {
  appearance: none;
  outline: none;
  border: none;
  background-color: orange;
  box-shadow: 0.1vw 0.1vw 0.5vw dimgray;
  border-radius: 0.5vw;
  padding: 0.5vw 2vw 0.5vw 2vw;
  color: black;
  font-weight: 700;
  font-size: 2vw;
  transition: all 0.3s;
  margin-top: 1vw;
}

@media (hover: hover) {
  .product-packing-button:hover,
  .product-packing-button:focus {
    box-shadow: 0.1vw 0.1vw 0.8vw orange;
    cursor: pointer;
  }
}

.product-packing-button:active {
  box-shadow: 0.1vw 0.1vw 1vw orange;
  background-color: orange;
}

.product-packing-button:disabled {
  box-shadow: 0.1vw 0.1vw 1vw darkorange;
  background-color: darkorange;
  cursor: auto;
}

.product-packing-spinner {
  margin-left: 0.5vw;
  width: 2vw !important;
  height: 2vw !important;
  border: 0.2vw solid currentColor !important;
  border-right-color: transparent !important;
}
/* 
@media screen and (max-width: 992px) {
  .product-packing-notification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5vw;
    min-height: 80vh;
    margin: 0.5vw;
  }
} */
